import React from "react";
import "./WeAreHiring.scss";

const WeAreHiring = (props) => (
  <section className="we_are_hiring_sec">
    <div className="container">
      <div className="we_are_hiring_wrapper">
        <div className="row align-items-center">
          <div className="col-md-7 col-lg-6 col-12">
            <div className="heading_wrapper mb-2">
              <h2 className="sec_heading sec_heading-medium">{props.title}</h2>
            </div>
            <div className="desc font18">
              <p>{props.description}</p>
            </div>
          </div>
          <div className="col-md-5 col-lg-6 col-12">
            <div className="we_are_hiring-btn mt-3 mt-md-0 text-md-end">
              <a
                rel="noreferrer"
                href={props.link}
                target="_blank"
                className="btn btn_secondary"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WeAreHiring;

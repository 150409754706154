import mybyk from "../images/cl_mybyk.png";
import storee from "../images/cl_storee.png";
import cw from "../images/cl_cw.png";
import dashel from "../images/cl_dashel.png";
import ethnotek from "../images/cl_ethnotek.png";
import jimmyjoy from "../images/cl_jimmijoy.png";
import ninjabet from "../images/cl_ninjabet.png";
import speedlab from "../images/cl_Speedlab.png";
import tadepole from "../images/cl_tadepole.png";
import teami from "../images/cl_teami.png";
import winmedia from "../images/cl_winmedia.jpg";
import wolf from "../images/cl_wolf.png";
import sunday from "../images/cl_sunday.png";
import chinuki from "../images/cl_chinuki.png";
import jaspar from "../images/cl_jaspar.png";
import basacial from "../images/cl_basacial.png";
const clientLogoData = [
  {
    id: 1,
    image: mybyk,
  },
  {
    id: 2,
    image: storee,
  },
  {
    id: 3,
    image: tadepole,
  },
  {
    id: 4,
    image: wolf,
  },
  {
    id: 5,
    image: ethnotek,
  },
  {
    id: 6,
    image: speedlab,
  },
  {
    id: 7,
    image: jimmyjoy,
  },
  {
    id: 8,
    image: ninjabet,
  },
  {
    id: 9,
    image: dashel,
  },
  {
    id: 10,
    image: winmedia,
  },
  {
    id: 11,
    image: teami,
  },
  {
    id: 12,
    image: cw,
  },
  {
    id: 13,
    image: chinuki,
  },
  {
    id: 14,
    image: jaspar,
  },
  {
    id: 15,
    image: basacial,
  },
  {
    id: 16,
    image: sunday,
  },
];

export default clientLogoData;

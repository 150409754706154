import React from "react";
import "./HomeLanding.scss";
import { Link } from "react-router-dom";
import { Link as Link1 } from "react-scroll";
// import { scroller } from "react-scroll";
// import Typical from "react-typical";
import TypewriterComponent from "typewriter-effect";
import HeroImg from "../../../utils/HeroImg";
// import { BASE_URL } from "../../../config";
import HeroMainImg from "../../../images/large-gradient-logo-icon.svg"
import HeroTopImg from "../../../images/hero_laptop_man_woman.svg"
import clutchBadge from "../../../images/clutch-100-list-badge.png"

const HomeLanding = (props) => {
  // scroller.scrollTo(".home_scroll_down a", {
  //   duration: 500,
  //   delay: 0,
  //   smooth: true,
  //   containerId: "home_service",
  // });

  return (
    <section className="home_landing_sec">
      <div className="bg-bottom-left"></div>
      <div className="bg-bottom-right"></div>
      <div className="container position-relative">
        <div className="row flip_mobile">
          <div className="col-md-6 col-12 align-self-center">
            <div className="home_landing_content">
              <h1 className="big_heading mb-3">
                {props.title}
                <span className="primary_text">
                  <TypewriterComponent
                    options={{
                      strings: ["Results.", "Solutions.", "Progress."],
                      autoStart: true,
                      loop: true,
                      pauseFor: 2000,
                      delay: 45,
                    }}
                  />
                  {/* <Typical
                    className="primary_text"
                    steps={[
                      "Results.",
                      2000,
                      "Solutions.",
                      3000,
                      "Progress.",
                      3000,
                    ]}
                    loop={Infinity}
                    wrapper="span"
                  /> */}
                </span>
              </h1>
              <div className="home_landing_desc mb-4">
                <p>{props.description}</p>
              </div>
              <Link to="/about" className="btn btn_secondary">
                {props.linkName}
              </Link>
              <div className="home_landing_clutch">
                <a href="https://clutch.co/press-releases/Clutch-100-fastest-growing-companies-2024?_gl=1*6ek1qv*_gcl_au*MTM5Nzk3MDU4MS4xNzExNTI0ODA0*FPAU*MTkxMzc2ODA1LjE3MTE1MjQ4MDY." target="_blank" rel="noreferrer">
                  <h4>We have been recognized as one of the 100 fastest-growing companies on Clutch.</h4>
                  <img src={clutchBadge} className="img-fluid" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 align-self-end">
            <div className="home_landing_img">
              <div className="home_landing_bg">
                <img
                  src={HeroMainImg}
                  width={611}
                  height={610}
                  alt=""
                  className="img-fluid"
                />
                <img
                  src={HeroTopImg}
                  width={563}
                  height={511}
                  alt=""
                  className="img-fluid home-landing-img"
                />
              </div>
              <div className="home_landing_animated">
                <HeroImg />
              </div>
            </div>
          </div>
        </div>

        <div className="home_scroll_down">
          <Link1 to="home_mybike" offset={-80}>
            Scroll for more
            <img
              src={process.env.PUBLIC_URL + "/images/arrow-down.svg"}
              alt=""
              height={"26px"}
              width={"26px"}
              className="img-fluid ms-2"
            />
          </Link1>
        </div>
      </div>
    </section>
  );
};

export default HomeLanding;

import React from "react";
import ServiceCard from "../Services/ServiceCard/ServiceCard";
import "./ServicesWeProvide.scss";

const ServicesWeProvide = (props) => (
  <section className="main-services">
    <div className="main-services__container">
      <div className="row">
        <div className="col-md-4 col-lg-3 col-12">
          <div className="main-services__content">
            <div className="heading_wrapper">
              <h2 className="sec_heading sec_heading-medium with_border">
                {props.title}
              </h2>
            </div>
            <div className="main-services__desc">
              <p>{props.description}</p>
              {/* <Link to="/services" className='btn btn_secondary'>{props.linkName}</Link> */}
            </div>
          </div>
        </div>
        <div className="col-md-8 col-lg-9 col-12">
          <div className="main-services__slider-wrapper">
            <ServiceCard linkName="Read More"></ServiceCard>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ServicesWeProvide;

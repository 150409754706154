import React from "react";
import workCardData from "../../../ComponentAPI/workCardAPI";
import "./WorkCard.scss";

const WorkCard = (props) => (
  <>
    {workCardData.map((val, index) => {
      return (
        <div className={"work-card " + val.clsName} key={index}>
          <div className="work-card__content">
            <h4 className="work-card__title">{val.title}</h4>
            <div
              className="work-card__desc"
              dangerouslySetInnerHTML={{ __html: val.description }}
            />
            {/* <Link to="/" className="btn btn-light">Read More</Link> */}
          </div>
          <div className="work-card__img">
            <img src={val.image} className="img-fluid" alt="" loading="lazy" width={val?.imgeWidth} height={val?.imageHeight} />
          </div>
        </div>
      );
    })}
  </>
);

export default WorkCard;

import React from "react";
import "./HomeMyBike.scss";
import kotlin from "../../../images/kotlin.svg";
import swift from "../../../images/swift.svg";
import php from "../../../images/php.svg";
import react from "../../../images/react.svg";
import nodejs from "../../../images/nodejs.svg";
import mongodb from "../../../images/mongodb.svg";
// import { Link } from "react-router-dom";
import mybykVideo from '../../../images/mybyk-new.mp4'

const technologyIconData = [
  {
    id: 1,
    image: kotlin,
  },
  {
    id: 2,
    image: swift,
  },
  {
    id: 3,
    image: php,
  },
  {
    id: 4,
    image: react,
  },
  {
    id: 5,
    image: nodejs,
  },
  {
    id: 6,
    image: mongodb,
  },
];

const HomeMyBike = (props) => {
  return (
    <section className="home_mybike_sec sec_pt sec_pb mybike_new" id="home_mybike">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 col-12">
            <div className="mybike_sec_img mb-4 mb-md-0">
              {/* <img
                src={process.env.PUBLIC_URL + "/images/mybyk-new.gif"}
                alt=""
                loading="lazy"
                className="img-fluid"
                width={473} height={364}
              /> */}
              <video src={mybykVideo} loop muted autoPlay playsInline></video>
            </div>
          </div>
          <div className="col-md-7 col-12">
            <div className="mybike_content_wrapper">
                <a href="https://www.youtube.com/watch?v=vj6PhzeTvIQ" rel="nofollow noreferrer" target="_blank" className="mybike_logo-list">
              <div className="mybike_featured-logos">
                <div className="mybike_featured">
                  
                  <img
                      src={process.env.PUBLIC_URL + "/images/featured-shark-tank-1.svg"}
                      alt=""
                      className="img-fluid mybyk-logo" width={240} height={152}
                    />
                </div>
                <div className="mybike_logo">
                  <img
                    src={process.env.PUBLIC_URL + "/images/logo-mybyk.png"}
                    alt=""
                    className="img-fluid mybyk-logo" width={110} height={34}
                  />
                </div>
              </div>
                </a>
              <div className="heading_wrapper">
                <h2 className="sec_heading sec_heading-medium">
                  {props.title}
                </h2>
              </div>
              <div className="desc mb-3">
                <p>{props.description}</p>
              </div>
              <ul className="technology__icons">
                {technologyIconData.map((val, index) => {
                  return (
                    <li key={index}>
                      <div className="technology__icon">
                        <img src={val.image} alt="" className="img-fluid" loading="lazy" width={40} height={40} />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMyBike;

import React from "react";
import enterpriseSoftwareSolutions from "../../ComponentAPI/enterpriseSoftwareSolutionsAPI";
import "./EnterpriseSoftwareSolutions.scss";

const EnterpriseSoftwareSolutions = (props) => (
  <section className="words_from_client_sec sec_pb we-ecommerce-view">
    <div className="container">
      <h2 className="sec_heading sec_heading-medium with_border">
        {props.title}
      </h2>
      <p className="we-ecommerce-view_desciption">{props.description}</p>
      <div id="pph-hireme"></div>
      <div className="col-12 row we-ecommerce-view_view mt-5">
        {enterpriseSoftwareSolutions.map((val) => {
          return (
            <div
              key={val.id}
              className="col-lg-4 col-md-6 we-ecommerce-view_card"
            >
              {val.pathUrl.length > 0 ? (
              // {val.id === 2 ? (
                <a
                  className="we-ecommerce-view_img_border"
                  href={val.pathUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={val.img}
                    className="we-ecommerce-view_img"
                    alt="We are Interactivated E Commerce"
                    loading="lazy"
                  />
                </a>
              ) : (
                <div className="we-ecommerce-view_img_border">
                  <img
                    src={val.img}
                    className="we-ecommerce-view_img"
                    alt="We are Interactivated E Commerce"
                    loading="lazy"
                  />
                </div>
              )}
              <h3 className="we-ecommerce-view__title col-md-10">
                {val.title}
              </h3>
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

export default EnterpriseSoftwareSolutions;

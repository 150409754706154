import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import technologyData from "../../../ComponentAPI/technologyAPI";
import "./TechnologyTabs.scss";

const TechnologyTabs = (props) => (
  <section className="technology">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="technology__heading">
            <div className="heading_wrapper">
              <h2 className="sec_heading sec_heading-medium with_border">
                {props.title}
              </h2>
            </div>
          </div>
          <div className="technology__tab-list">
            <Tabs>
              {technologyData.map((val, index) => {
                return (
                  <Tab eventKey={"tab-" + index} key={index} title={val.title}>
                    <div className="technology__tab-wrapper">
                      <div className="technology__img">
                        <img src={val.mainImg} alt={val.title} loading="lazy" />
                      </div>
                      <ul className="technology__icon-list list-unstyled">
                        {val.images.map((iconVal, iconIndex) => {
                          return (
                            <li key={iconIndex}>
                              <img
                                src={iconVal.image}
                                className="img-fluid"
                                alt=""
                                loading="lazy"
                                width={41} height={41} 
                              />
                              <span className="technology__icon-name">
                                {iconVal.title}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default TechnologyTabs;

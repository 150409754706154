import React from "react";
import HomeLanding from "../../component/Home/HomeLanding";
import ServicesWeProvide from "../../component/ServicesWeProvide";
import HomeMyBike from "../../component/Home/HomeMyBike";
import WhatWeOffer from "../../component/Home/WhatWeOffer";
// import Blog from "../../component/Blog";
import HireYourTeam from "../../component/Home/HireYourTeam";
import WordsFromClients from "../../component/WordsFromClients";
import WeAreHiring from "../../component/WeAreHiring";
import Work from "../../component/Work";
import Statistics from "../../component/Statistics";
import TechnologyTabs from "../../component/Technologies/TechnologyTabs";
import ClientLogos from "../../component/ClientLogos";
import AwardsAndAchievements from "../../component/AwardsAchievements";
import EnterpriseSoftwareSolutions from "../../component/EnterpriseSoftwareSolutions";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="page_content mobile_page_padding">
      <Helmet>
        <title>Web & Mobile Apps Software Development Company, IT Services & Solutions | eQuest Solutions</title>
        <meta name="description" content="eQuest Solutions is a Leading Custom Software Development, E-commerce, Web & Mobile Development Company that offers top-notch services to build scalable custom software solutions for enterprises and startups." />
      </Helmet>
      <HomeLanding
        title={
          <>
            your ideas. Our Innovation. <br /> Robust &nbsp;
          </>
        }
        description={
          <>
            We provide quick and efficient design and development solutions for
            <span> web</span>, <span>mobile</span>, <span>cloud</span> and
            <span> IoT</span> with 100% delivery successes.
          </>
        }
        linkName="Find Out More"
      ></HomeLanding>

      <HomeMyBike
        title={
          <>
            India’s First Bike Sharing Program
            <span className="app_color"> MYBYK</span> runs on our code
          </>
        }
        description="A full-fledged, easy-to-scale, cloud-based solution that would take care of their heavy inbound & outbound traffic, registrations, and cycle-flow."
        linkName={<>Learn more </>}
      ></HomeMyBike>

      <WhatWeOffer
        title={
          <>
            what <br />
            we offer?
          </>
        }
      ></WhatWeOffer>

      <ServicesWeProvide
        title="Services We Provide"
        description="We are not just an outsourced company but a digital tech partner to our clients, aiding their business growth potential to reach heights."
        linkName=""
      ></ServicesWeProvide>

      <Work title="Our Work"></Work>
      <Statistics
        title="From Conceptualization to Completion"
        description="Ever since the inception in 2012 the team at eQuest has been delivering top-quality software solutions. We have built and maintained our global client base with our sheer efforts & dedication. The round-the-clock availability of our business analysts, designers, and developers help our clients to get real-time solutions to their problems."
        linkName="About Us"
      ></Statistics>

      <TechnologyTabs title="Our Expertise"></TechnologyTabs>

      <ClientLogos title="Prestigious Clients"></ClientLogos>

      <HireYourTeam
        title="hire your best team"
        description="We are an all-rounder service-providing company that welcomes ideas and customizations from the client's end with open arms. Choose us as your digital partner and capture the market share you have been dreaming of…"
        linkName="Let’s work together"
      ></HireYourTeam>
      <WordsFromClients
        title={
          <>
            words from <span className="highlighted-text">clients</span>
          </>
        }
      />
      <EnterpriseSoftwareSolutions
        title={"Enterprise Software Solutions, Accredited By"}
        description={""}
      />
      <AwardsAndAchievements title={"Awards & Achievements"} />
      <WeAreHiring
        title={
          <>
            We are <span className="teal_text">hiring now</span>
          </>
        }
        description={
          "Join our ever expanding web development and business development teams. Apply today!"
        }
        link={"https://forms.gle/vZ2sduLBcvTeZ61L9"}
      ></WeAreHiring>
    </div>
  );
};

export default Home;
